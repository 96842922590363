.modalSubscribe{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.modalSubscribe h2{
    font-weight: 700;
    font-size: 32px;
    font-family: 'BeelineSans';
    font-style: normal;
    font-size: 32px;
    line-height: 34px;
    color: #000000;
    margin-bottom: 32px;
}

.modalSubscribe .regularText{
    color: #191C22;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.modalSubscribe .smallText{
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    color: #727281;
    margin-top: 16px;
    line-height: 15px;
    white-space: break-spaces;
}

.modalSubscribe .smallText a{
    all: unset;
    cursor: pointer;
    color: #1790ff;
}

.modalSubscribe .phoneNumber{
    margin-top: 8px;
    margin-bottom: 32px;
    color: #191C22;
    font-family: BeelineSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 44px;
    border-radius: 8px;
    width: 100%;
    background: #fff;
    border: 1px solid #DFE0E5;
    padding: 0 12px;
    text-align: left;
    outline: none;
    box-sizing: border-box;
}

.modalSubscribe .errorText{
    color: red;
}

.modalSubscribe .errorBorder{
    border: 1px solid red !important;
    color: red !important;
}

.modalSubscribe .waitText{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: #727281;
}

.modalSubscribe .waitText a{
    all: unset;
    cursor: pointer;
    text-decoration: underline;
}

.modalSubscribe .bb_spinner{
    margin-top: 64px;
}

.modalSubscribe .subscribePreInfo-wrapper{
    width: 100%;
    margin-bottom: 20px;
}

.modalSubscribe .subscribePreInfo-wrapper span, .greyText{
    font-family: 'Oficina', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #9E9E9E;
    margin-top: -10px;
    white-space: break-spaces;
}

.modalSubscribe .subscribePreInfo{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: 'Oficina', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #212121;
    margin-top: 5px;
}

.modalSubscribe .subscribePreInfo-title{
    flex: 1;
}

.modalSubscribe .subscribePreInfo-price{
    margin-right: 5px;
}

.modalSubscribe .subscribePreInfo-edit{
    cursor: pointer;
}

.modalSubscribe .periodSelection-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 32px 0;
    gap: 15px;
    width: 100%;
    justify-content: space-between;
}

.modalSubscribe .periodSelection-item{
    box-sizing: border-box;
    font-family: 'Oficina', sans-serif;
    border: 1px solid #E7E8EB;
    border-radius: 12px;
    position: relative;
    padding: 12px 16px;
    cursor: pointer;
    background: #fff;
    flex: 1;
}

.modalSubscribe .periodSelection-item.selected{
    border: 1px solid #212121;
}

.modalSubscribe .periodSelection-item.active{
    border: 1px solid #212121;
}

.modalSubscribe .periodSelection-item-badge{
    position: absolute;
    padding: 5px 10px;
    border-radius: 20px;
    background: #FFE632;
    right: 12px;
    top: -16px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #212529;
}

.modalSubscribe .periodSelection-item-title{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #191C22;
    margin-bottom: 5px;
    margin-top: 5px;        
}

.modalSubscribe .periodSelection-item-monthPrice{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #727281;
    margin-bottom: 15px;
}

.modalSubscribe .periodSelection-item-price-wrapper{
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
}

.modalSubscribe .periodSelection-item-price-total{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    text-align: left;
    color: #191C22;
}

.modalSubscribe .periodSelection-item-price-full{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    float: right;
    color: #727281;
    text-decoration: line-through;
}

.modalSubscribe .errorImage{
    margin-bottom: 24px;
}

.modalSubscribe .error_Description{
    color: #5A5E62;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}

.modalSubscribe .downloadPanel-badges{
    width: 100%;
    margin-top: 30px;
    margin-bottom: 0px;
}