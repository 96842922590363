.FAQ-wrapper{
    width: 100%;
    background: #F9F9F9;
    border-radius: 16px;
    padding: 80px 80px 48px 80px;
    border: 1px solid #DFE0E5;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 40px 0 120px;
}

.FAQ-wrapper h2{
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 52px;
    color: #000000;
    margin: 0;
}