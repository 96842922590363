.bookContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.bookContainer-cover{
    width: 100%;
    position: relative;
    aspect-ratio: 100/143;
    height: fit-content;
}

.bookContainer-cover>img{
    width: 100%;
    aspect-ratio: 100 / 143;
    border-radius: 4px;
    border: 1px solid #efefef;
    box-sizing: border-box;
}

.bookContainer-cover:has(.adultMask) .bookContainer-cover-vinil{
    display: none;
}

.bookContainerWrapper{
    flex: 1;
    width: 0;
    max-width: 35%;
}

.bookContainer-title{
    cursor: pointer;
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    margin-top: 12px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #212529;    
}

.bookContainer-author{
    cursor: pointer;
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    margin-top: 8px;
    color: #9091AA;
}

.bookContainer.audioBook .bookContainer-cover{
    aspect-ratio: 1/1;
}

.bookContainer.audioBook .bookContainer-cover>img{
    aspect-ratio: 1/1;
}

.bookContainer.audioBook .bookContainer-cover-vinil{
    position: absolute;
    width: 13%;
    top: 40%;
    right: -3%;
    aspect-ratio: 37 / 74;
}

.bookContainer.audioBook .bookContainer-cover-vinil img{
    width: 100%;
    height: 100%;
}