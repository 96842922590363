.modalDownloadApp h2{
    font-weight: 700;
    font-size: 32px;
    font-family: 'BeelineSans';
    font-style: normal;
    font-size: 32px;
    line-height: 52px;
    color: #000000;
    margin-bottom: 20px;
}

.modalDownloadApp .badges{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
}

.modalDownloadApp .badges-column{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    gap: 12px;        
}

.modalDownloadApp .badges-column img{
    width: 100%;
}

.modalDownloadApp .badges-column svg{
    width: 145px;
}

.modalDownloadApp .badges-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    gap: 12px;        
}

.modalDownloadApp .badges-row img{
    width: 100%;
}

.modalDownloadApp .badges-row svg{
    width: 100%;
}

.modalDownloadApp .qrWrapper{
    width: 150px;
    background: #F5F5F5;
    border-radius: 16px;
    padding: 12px;
    box-sizing: border-box;
}

.modalDownloadApp .qrWrapper img{
    width: 100%;
}

.modalDownloadApp .qr-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    gap: 12px;
    gap: 20px;
}