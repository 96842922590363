  .swiperMainBanner-wrapper{
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiperMainBanner .swiper-wrapper{
    display: flex;
    align-items: center;
  }
  
  .swiperMainBanner .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiperMainBanner .swiper-slide {
    /* width: auto; */
    width: 100%;
  }

  .swiperMainBanner .SliderCentered-wrapper{
    background: #E9FFCE;
    border-radius: 8px;
    padding: 25px 32px;
    /* padding-left: 153px; */
    /* padding-right: 32px; */
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    width: 100%;
    /* margin-bottom: 40px; */
    margin-left: 45px;
  }

  .swiperMainBanner-next{
    width: 50px;
    height: 50px;
    background: #f2f2f280;
    border-radius: 50%;
    z-index: 90;
    position: absolute;
    top: calc(50% - 25px);
    right: calc(50% - 620px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .swiperMainBanner-prev{
    width: 50px;
    height: 50px;
    background: #f2f2f280;
    border-radius: 50%;
    z-index: 90;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 620px);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .swiperMainBanner-prev svg{
    padding-left: 6px;
  }