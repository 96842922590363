.ageNotificationPanel{
    width: 348px;
    background: #433F3F;
    padding: 24px 32px;
    border-radius: 32px;
    z-index: 50;
    font-family: BeelineSans;
    margin-bottom: 20px;
    margin-right: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    gap: 15px;    
}

.ageNotificationPanel p{
    color: #fff;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;    
}

.ageNotificationPanel p.smallText{
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

