.subsGrid{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    width: 100%;
}

.subsGrid .subsGrid-item{
    flex: 1;
    border: 1px solid #DFE0E5;
    border-radius: 16px;
    background: #fff;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

.subsGrid .subsGrid-item .topContent{
    background: #F9F9F9;
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 84px 28px 0 28px;
    box-sizing: border-box;
    position: relative;
    height: 330px;
}

.subsGrid .subsGrid-item .topContent .badge{
    left: 28px;
    top: 32px;
    position: absolute;
    background: #191C22;
    padding: 3px 11px;
    border-radius: 8px;
    color: #FDFDFE;
    font-family: BeelineSans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #191C22;
}

.subsGrid .subsGrid-item .topContent .title{
    margin: 0;
    padding: 0;
    color: #191C22;
    font-family: 'BeelineSans';
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 6px;
}

.subsGrid .subsGrid-item .topContent .description{
    margin: 0;
    padding: 0;
    color: #191C22;
    font-family: BeelineSans;
    font-size: 17px;
    font-style: normal;
    font-weight: 100;
    line-height: 22px;
    width: 100%;
    white-space: break-spaces;
}

.subsGrid .subsGrid-item .topContent .catImage{
    height: 140px;
    position: absolute;
    right: calc(50% - 107px);
    bottom: 0;
}

.subsGrid .subsGrid-item .topContent .catImage img{
    height: 100%;
}

.subsGrid .subsGrid-item .bottomContent{
    padding: 8px 24px 24px 24px;
    box-sizing: border-box;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem:nth-child(2){
    border-top: 1px solid #E7E8EB;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    gap: 15px;
    align-items: center;
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
    justify-content: space-between;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButton{
    width: 18px;
    height: 18px;
    border-radius: 10px;
    border: 1px solid #808080;
    overflow: hidden;
    box-sizing: border-box;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButton .circle{
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 2px solid #fff;
    background: #fff;
    box-sizing: border-box;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem.active .radioButton .circle{
    background: #808080;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButtonDecription{
    flex: 1;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButtonDecription .firstLine{
    font-family: 'BeelineSans';
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: left;
    color: #191C22;
    margin-bottom: 3px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButtonDecription .secondLine{
    font-family: 'BeelineSans';
    font-size: 15px;
    font-weight: 100;
    line-height: 20px;
    text-align: left;
    color: #9e9e9e;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButtonDecription .secondLine span{
    text-decoration: line-through;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButtonPrice{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButtonPrice .badge{
    font-size: 12px;
    font-weight: 400;
    background: #1EBEAB;
    box-sizing: border-box;
    padding: 4px 12px;
    border-radius: 8px;
    color: #FDFDFE;
}

.subsGrid .subsGrid-item .bottomContent .radioButtons .radioButtonsItem .radioButtonPrice .price{
    font-family: BeelineSans;
    font-size: 16px;
    font-weight: 400;
    color: #9E9E9E;
    width: 40px;
    text-align: right;
}

.subsGrid .subsGrid-item .bottomContent .buttonArea{
    margin-top: 8px;
    z-index: 1;
    position: relative;
    display: flex;
    justify-content: center;
}

.subsGrid .subsGrid-item .bottomContent .tarifupWrapper{
    bottom: 0px;
    left: 0;
    height: 96px;
    position: absolute;
    width: 100%;
    border-radius: 14px;
    z-index: 0;
    background: linear-gradient(90deg, #646FFF 0%, #8429FF 100%);
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    padding-top: 4px;
    box-sizing: border-box;
}

.subsGrid .subsGrid-item:hover .topContent, .subsGrid .subsGrid-item.active .topContent{
    background: #FFE632;
}

.subsGrid .subsGrid-item:hover .bottomContent .button,  .subsGrid .subsGrid-item.active .button{
    color: #313131;
    background: #FFE632;
    border: 1px solid #FFE632;
}