.newYear2024-left{
    width: 14vw;
    /* width: 17vw; */
    position: fixed;
    /* top: 163px; */
    /* top: 103px; */
    bottom: 103px;
    left: 0;
    z-index: -1;
}
.newYear2024-right{
    width: 12vw;
    /* width: 19vw; */
    /* width: 32vw; */
    position: fixed;
    /* bottom: 163px; */
    /* bottom: 83px; */
    /* bottom: 123px; */
    top: 153px;
    right: 0;
    z-index: -1;
}

.newYear2024-left img, .newYear2024-right img{
    width: 100%;
}