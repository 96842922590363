.appBonuses-wrapper {
    --panel-gap: 30px;
}

.appBonuses-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: var(--panel-gap);
    box-sizing: border-box;
    margin-top: 30px;
}

.appBonuses-item{
    width: calc(50% - (var(--panel-gap) / 2));
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    border-radius: 16px;
    border: 1px solid #DFE0E5;
    overflow: hidden;
}

.appBonuses-item-left{
    flex: 4;
    display: flex;
}

.appBonuses-item-left img{
    width: 100%;
}

.appBonuses-item-right{
    flex: 8;
}

.appBonuses-item-right h2{
    color: #191C22;
    font-family: BeelineSans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 0;
    margin: 0;
    margin-bottom: 12px;
}

.appBonuses-item-right p{
    color: #727281;
    font-family: BeelineSans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 0;
    margin: 0;
    margin-right: 30px;
}