.shelfItem{
    width: 100%;
    display: flex;
    margin-bottom: 60px;
}

.shelfItem>a{
    all: unset;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
}

.shelfItem.audioBook .cover{
    aspect-ratio: 1/1;
}

.shelfItem .cover{
    aspect-ratio: 100/143;
    position: relative;
}

.shelfItem .cover>img{
    width: 100px;
    height: 100%;
    border-radius: var(--book-border-radius);
    border: 1px solid #dedede;
}

.shelfItem .description{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.shelfItem .description .title h2{
    font-family: 'AvenirNext-Bold';
    font-size: 22px;
    line-height: 22px;
    color: #393939;
    margin-bottom: 12px;
}

.shelfItem .description .annotation p{
    font-family: 'BeelineSans';
    font-size: 16px;
    line-height: 22px;
    color: #000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.shelfItem:has(.adultMask) .bookContainer-cover-vinil{
    display: none;
}

.shelfItem.audioBook .bookContainer-cover-vinil{
    position: absolute;
    width: 13%;
    top: 40%;
    right: -3%;
    aspect-ratio: 37 / 74;
}

.shelfItem.audioBook .bookContainer-cover-vinil img{
    width: 100%;
    height: 100%;
}