.bookContainerHorisontalWrapper .bookContainerHorisontal{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 20px;
    gap: 20px;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-cover{
    position: relative;
    height: fit-content;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-cover>img{
    aspect-ratio: 100 / 143;
    border-radius: 4px;
    border: 1px solid #efefef;
    box-sizing: border-box;
    height: 150px;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-cover:has(.adultMask) .bookContainer-cover-vinil{
    display: none;
}

.bookContainerHorisontalWrapper{
    flex: 1;
    /* width: 0; */
    /* max-width: 35%; */
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-title{
    cursor: pointer;
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #212529;    
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-author{
    cursor: pointer;
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #9091AA;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-description{
    cursor: pointer;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: #212529;
    margin-top: 8px;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal.audioBook .bookContainerHorisontal-cover>img{
    aspect-ratio: 1/1;
    height: 150px;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal.audioBook .bookContainerHorisontal-cover-vinil{
    position: absolute;
    width: 13%;
    top: 40%;
    right: -3%;
    aspect-ratio: 37 / 74;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal.audioBook .bookContainerHorisontal-cover-vinil img{
    width: 100%;
    height: 100%;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-content{
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-rating{
    font-family: BeelineSans;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    display: flex;
    align-items: center;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-rating svg{
    font-size: 16px;
}

.bookContainerHorisontalWrapper .bookContainerHorisontal-buttons{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 200px;
    align-self: center;
}