.sR-title{
    background: linear-gradient(134.36deg, #FAFCFE 1.1%, #D5E4F0 100%);
    border-radius: 5px;
    height: 30px;
    width: 220px;    
}

.sR-bookTitle{
    background: linear-gradient(134.36deg, #FAFCFE 1.1%, #D5E4F0 100%);
    border-radius: 5px;
    height: 32px;
    width: 90%;
}

.sR-bookAuthor{
    background: linear-gradient(134.36deg, #FAFCFE 1.1%, #D5E4F0 100%);
    border-radius: 5px;
    height: 15px;
    width: 70%;
}