.shelfSearchResultWrapper{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin-bottom: 20px;
}

.shelfSearchResultWrapper .shelfTitle{
    font-family: BeelineSans;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #313131;
    margin-bottom: 20px;
}

.shelfSearchResultWrapper .shelfBooks{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 170px;
    gap: 20px;
    align-items: center;
}

.shelfSearchResultWrapper .shelfBooks .bookCover{
    height: 100%;
    aspect-ratio: 100/143;
    position: relative;
    box-sizing: border-box;
}

.shelfSearchResultWrapper .shelfBooks .bookCover.audio{
    aspect-ratio: 1/1;
}

.shelfSearchResultWrapper .shelfBooks .bookCover img{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.shelfSearchResultWrapper .shelfBooks .shelfLink{
    margin-left: auto;
    display: flex;
    align-items: center;
}

.shelfSearchResultWrapper .shelfBooks .shelfLink svg{
    margin-left: 3px;
    height: 13px;
}

.shelfSearchResultWrapper .shelfBooks .bookCover:has(.adultMask) .bookContainer-cover-vinil{
    display: none;
}

.shelfSearchResultWrapper .shelfBooks .bookCover.audio .bookContainer-cover-vinil{
    position: absolute;
    width: 13%;
    top: 40%;
    right: -3%;
    aspect-ratio: 37 / 74;
}

.shelfSearchResultWrapper .shelfBooks .bookCover.audio .bookContainer-cover-vinil img{
    width: 100%;
    height: 100%;
}