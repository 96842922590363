.readMeBanner-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    height: 405px;
    position: relative;
}

.readMeBanner-content-covers{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 3px;
}
.readMeBanner-content-cover{
    flex: 1;
}

.readMeBanner-content-cover img{
    width: 100%;
    border-radius: var(--book-border-radius);
    aspect-ratio: 183/281;
}

.readMeBanner-content-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    background: #f5f5f5;
    flex: 58;
}

.readMeBanner-content-left .qrArrow{
    margin-left: auto;
    height: 16px;
    margin-right: 32px;
    margin-top: 20px;
}

.readMeBanner-content-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    background: url("../../../img/reameImage.jpg");
    flex: 81;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.readMeBanner-content-title{
    color: #313131;
    font-family: BeelineSans;
    font-size: 44px;
    font-weight: 700;
    line-height: 46px;
    text-align: left;
    margin-left: 64px;
}

.readMeBanner-content-button{
    margin-top: 24px;
    margin-bottom: 24px;
}

.readMeBanner-content-smallText{
    color: #313131;
    font-family: 'BeelineSans';
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-left: 64px;
    margin-top: 32px;
}

.readeMeQr{
    position: absolute;
    top: calc(50% - 85px);
    left: 37%;
    box-sizing: border-box;
    background: #fff;
    height: 170px;
    width: 170px;
    border: 2px solid #000;
    border-radius: 13px;
    padding: 10px;
}

.readeMeQr img{
    height: 100%;
    width: 100%;
}