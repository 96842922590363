.downloadPanel-wrapper{
    width: 100%;
    background: #F9F9F9;
    border-radius: 16px;
    padding: 40px 80px 0 80px;
    border: 1px solid #DFE0E5;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing: border-box;
    margin: 40px 0 0;
    gap: 15px;
}

.downloadPanel-left{
    flex: 7;
}

.downloadPanel-right{
    flex: 5;
    display: flex;
    align-items: flex-end
}

.downloadPanel-right img{
    width: 100%;
}

.downloadPanel-text h2{
    color: #191C22;
    font-family: BeelineSans;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    margin: 0;
    padding: 0;
    margin-bottom: 16px;
}

.downloadPanel-text p{
    color: #191C22;
    font-family: BeelineSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    padding: 0;
    margin-bottom: 32px;
}

.downloadPanel-badges{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    gap: 24px;
}

.downloadPanel-badges-column{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    gap: 12px;
    align-items: center;
}

.downloadPanel-badges-column img.qrCode{
    max-width: 100%;
}