.page-content:has(.searchContent){
    flex: 1;
    background: #f5f5f5;
    justify-content: flex-start;
}

.contentWrapper:has(.searchContent){
    width: 100%;
}

.contentWrapper .s-bg:has(.searchContent), .searchContent .s-bg {
    max-width: 1228px;
}

.searchContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.searchContent .searchResults{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 44px;
    border-radius: 50px;
    background: #fff;
    width: 100%;
    margin-bottom: 35px;
}

.searchContent .searchResult{
    border-bottom: 1px solid #F1F1F1;
    margin-bottom: 30px;
}

.searchContent .searchResult:last-child{
    border: none;
    margin-bottom: 0;
}

.searchContent .searchResults .searchResultTitle{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 35px;
}

.searchContent .searchResults .searchResultTitle .searchType{
    font-family: BeelineSans;
    font-size: 30px;
    font-weight: 500;
    line-height: 33px;
}

.searchContent .searchResults .searchResultTitle .searchCount{
    font-family: BeelineSans;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.searchContent .searchResults .searchResultTitle .searchCount svg{
    height: 13px;
}

.searchContent .searchResults .searchResultContent{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 30px;
}

.searchContent .searchResults .searchResultContent .bookContainerHorisontalWrapper:last-child .bookContainerHorisontal{
    border-bottom: none;
}

.searchContent .searchResults .searchResultAllButton{
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9E9E9E;
    font-family: BeelineSans;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    margin: 10px 0 20px;
    cursor: pointer;
}

.searchContent .searchResults .searchResult:last-child .searchResultAllButton{
    margin-bottom: 0;
}

.searchContent .searchResults .searchResultAllButton svg{
    font-size: 16px;
    margin-left: 3px;
}

.searchContent .searchResults .searchResultDivider{
    width: 100%;
    margin: 20px 0 40px;
    border-bottom: 1px solid #F1F1F1;
}

.searchContent .searchResults:has(.emptyResult){
    background: none;
}

.searchContent .searchResults .emptyResult{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
}

.searchContent .searchResults .emptyImg{
    width: 250px;
}

.searchContent .searchResults .emptyImg img{
    width: 100%;
}

.searchContent .searchResults .emptyText{
    font-family: "Roboto";
    font-size: 18px;
    line-height: 26px;
    font-weight: 500;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.searchContent .searchPanel{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 44px;
    border-radius: 0 0 50px 50px;
    background: #fff;
    width: 100%;
    margin-bottom: 35px;
}

.searchContent .searchPanel .searchInput{
    height: 40px;
    width: 100%;
    background: #F8F8F8;
    border-radius: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    padding-left: 20px;
    gap: 10px;
}

.searchContent .searchPanel .searchInput .searchValue{
    flex: 1;
    border: none;
    background: #F8F8F8;
    outline: none;
    font-family: BeelineSans;
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
}

.searchContent .searchPanel .searchInput .clearButton{
    margin-left: auto;
    height: 16px;
    width: 16px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.searchContent .searchPanel .searchInput .searchButton{
    margin-left: auto;
    background: #CDCDCD;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center
}

.searchContent .searchPanel .searchCounts{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
    white-space: nowrap;
}

.searchContent .searchPanel .searchCounts .item{
    font-size: 14px;
    font-weight: 500;
    color: #313131;
    background: #F5F5F5;
    padding: 10px 16px;
    border-radius: 24px;
    cursor: pointer;
}

.searchContent .searchPanel .searchCounts .item span{
    color: #9E9E9E;
}

.searchContent .searchPanel .searchCounts .item.active{
    background: #313131;
    color: #fff;
}

.searchContent .searchPanel .searchCounts .item.empty{
    cursor: default;
    background: none;
    color: #9E9E9E;
}

.searchContent .searchPanel .searchCounts .searchSmallText{
    margin-left: auto;
    font-family: 'Avenir Next';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.searchContent .searchBottomText{
    font-family: BeelineSans;
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
    color: #9e9e9e;
    margin-bottom: 44px;
    width: 60%;
}