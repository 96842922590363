.contentWrapper:has(.subsInfo){
    width: 100%;
}

.subsInfo{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
}

.FAQ-wrapper .description{
    margin-top: 15px;
}

.FAQ-wrapper .description ul{
    padding-left: 15px;
}

.FAQ-wrapper .description li{
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
    color: #9E9E9E;
}

.subsInfo .titleRow h2{
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    color: #000000;
    margin-bottom: 30px;
    margin-top: 30px;
}

.subsInfo .bonusesWrapper{
    padding: 40px 0;
    background: #5ACABB63;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subsInfo .bonusesWrapper .bonuses{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
    justify-content: space-between;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem{
    flex: 1;
    background: #fff;
    border-radius: 16px;
    border: 1px solid #DFE0E5;
    width: 100%;
    height: 331px;
    overflow: hidden;    
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem .topLine, .subsInfo .bonusesWrapper .bonuses .bonusesItem .bottomLine{
    flex: 1;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem h3{
    padding: 16px 16px 12px;
    font-family: 'BeelineSans';
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.1em;
    text-align: left;
    margin-bottom: .4em;
    color: #000;
    margin: 0;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem p{
    padding: 0 16px;
    font-family: 'BeelineSans';
    font-size: 1.1em;
    font-weight: 400;
    line-height: 1.2em;
    text-align: left;
    margin-top: 0;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem img{
    max-width: 100%;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem img.bonusImg1{
    width: 100%;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem img.bonusImg2{
    height: 140px;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem img.bonusImg3{
    height: 140px;
    margin-left: auto;
    margin-bottom: 20px;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem img.bonusImg4{
    height: 120px;
}

.subsInfo .bonusesWrapper .bonuses .bonusesItem .topLine.centeredImg, .subsInfo .bonusesWrapper .bonuses .bonusesItem .bottomLine.centeredImg{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}