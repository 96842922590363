.contentWrapper:has(.shelfContent){
    width: 100%;
}

.shelfContent{
    width: 100%;
}

.shelfContent>.title{
    text-align: center;
    margin-bottom: 80px;
    margin-top: 90px;
}

.shelfContent>.title h1{
    font-family: 'Oficina', sans-serif;
    font-size: 34px;
    width: 100%;
    font-weight: 400;
}

.shelfContent>.descipriton{
    margin-bottom: 35px;
}

.shelfContent>.descipriton p{
    font-family: 'Oficina', sans-serif;
    text-align: left;
    font-weight: 500;
    line-height: 1.2;
    font-size: 28px;
}
