.FAQitem{
    border-bottom: 1px solid #DFE0E5;
}

.FAQitem-title{
    color: #191C22;
    font-family: BeelineSans;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    height: 24px;
    cursor: pointer;
}

.FAQitem-text{
    color: #191C22;
    font-family: BeelineSans;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    margin: 0 0 32px 0;
}

.FAQitem-text p{
    margin-bottom: 20px;
}

.FAQitem-topline{
    margin: 32px 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center
}

.FAQitem-toggleButton{
    cursor: pointer;
}

.FAQitem-text ul{
    margin-left: 30px;
    margin-bottom: 20px;
}