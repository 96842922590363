a:has(.button){
    all: unset;
    display: contents;
}

.button{
    /* float: right; */
    border-radius: 8px;
    background: #FFE632;
    padding: 10px 30px;
    color: #191C22;
    text-align: center;
    font-family: 'BeelineSans';
    font-size: 16px;
    font-style: normal;
    font-weight: 100;
    line-height: 20px;
    width: fit-content;
    cursor: pointer;
    border: 1px solid #FFE632;
    text-decoration: none;
    box-sizing: border-box;
    white-space: break-spaces;
}

.button.noButton{
    background: none;
    padding: unset;
    margin: unset;
    border: none;
    height: 38px;
}

.button.noButton img{
    height: 100%;
}

.button.thin{
    padding-top: 8px;
    padding-bottom: 8px;
    font-weight: 100;
}

.button.bw{
    background-color: unset;
    border: 1px solid #9E9E9E;
    color: #9E9E9E;
}

.button.w-144{
    width: 144px;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.button.ptb-5{
    padding-top: 5px;
    padding-bottom: 5px;
}

.button.ptb-6{
    padding-top: 6px;
    padding-bottom: 6px;
}

.button.ptb-7{
    padding-top: 8px;
    padding-bottom: 8px;
}

.button.ptb-10{
    padding-top: 10px;
    padding-bottom: 10px;
}

.button.ptb-15{
    padding-top: 15px;
    padding-bottom: 15px;
}

.button.ptb-20{
    padding-top: 20px;
    padding-bottom: 20px;
}

.button.mtb-20{
    margin-top: 20px;
    margin-bottom: 20px;
}

.button.mb-20{
    margin-bottom: 20px;
}

.button.plr-20{
    padding-left: 20px;
    padding-right: 20px;
}

.button.plr-15{
    padding-left: 15px;
    padding-right: 15px;
}

.button.black{
    background: #000;
    color: #fff;
    border: 1px solid #000;
}

.button.lightGrey{
    background: #F1F1F1;
    color: #313131;
    border: 1px solid #fff;
}


.button.violet{
    background: linear-gradient(90deg, #213184 0%, #3A4B9D 100%);
    color: #fff;
    border: none;
}

.button.horizontalIcon{
    display: flex;
    gap: 10px;
}

.button.horizontalIcon img{
    height: 20px;
}

.button.grey{
    background: #F5F5F5;
    color: #313131;
    border: 1px solid #fff;
}

.button.green{
    background: #2EC354;
    color: #FFF;
    border: 1px solid #2EC354;
}

.button.w-fw{
    width: 100%;
    box-sizing: border-box;
}

.button.w-300{
    width: 300px;
}

.button.hoverYellow, .button.hoverGrey{
    transition-duration: .2s;
}

.button.hoverYellow:hover{
    background: #FFCF14;
}

.button.hoverGrey:hover{
    color: #FFFFFF;
    background: #433F3FE5;
}

.button.withPrice{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
}

.button .buttonPrice{
    border-left: 1px solid #C29553;
    margin-left: auto;
    padding: 5px 0px 5px 16px;
    
}

.button .buttonText{
    text-align: center;
    flex: 1;
    width: 100%;
}

.button.grey .buttonPrice{
    border-left: 1px solid #9E9E9E;
    margin-left: auto;
    padding: 5px 0px 5px 16px;
}

.button.saleButton{
    background: #FED0EF;
    color: #313131 !important;
}

.button.saleButton:hover{
    background: #ffb9e8;
}

.button.white{
    background: #fff;
    color: #313131;
    border: 1px solid #191C22
}

.button.bp-nb .buttonPrice{
    border: none;
}

.button.disabled{
    cursor: default;
    color: #A7ABB0;
    border: 1px solid #D7D9DC;
}

.button.disabled.white{
    background: #fff !important;
    border: 1px solid #D7D9DC !important;
}

.button.upColor, .button.upColor:hover{
    color: #fff;
    background: linear-gradient(90deg, #646EFF 0%, #7F31FF 100%);
    border: 1px solid transparent;
}

.button.upColor .buttonPrice{
    border-left: 1px solid #fff;
}