.page-content:has(.notFound){
    flex: 1;
}

.notFound{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.notFound .square{
    border: 2px solid #e0dfdf;
    width: 200px;
    height: 96px;
    border-radius: 11px;
    text-align: center;
    margin-top: -40px;
}

.notFound .square h1{
    font-size: 64px;
    font-family: 'Oficina', sans-serif;
    font-weight: 900;
    margin-top: 12px;
}

.notFound .bigText{
    font-family: 'Oficina', sans-serif;
    /* font-weight: 900; */
    margin-top: 12px;
    text-align: center;
    margin-top: 40px;    
    margin-bottom: 60px;
}

.notFound .bigText h2{
    font-size: 44px;
    font-weight: 100;
}