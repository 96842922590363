.notificationArea{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 99;
}