:root {
    /* --main-content-width: 984px; */
    --main-content-width: 1140px;
    --book-border-radius: 4px;
    --bigbook-border-radius: 16px;
    --singlebook-border-radius: 8px;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;600&display=swap');

/* @font-face {
    font-family: 'BeelineSans';
    src: local('MyFont'), url(./fonts/MyFont.woff) format('woff');
} */

@font-face {
    font-family: BeelineSans;
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    /* src: local("BeelineSans"),local("BeelineSans-Bold"),url('../fonts/BeelineSans-Bold.woff2') format("woff2") */
    src: url('../fonts/BeelineSans/BeelineSans-Bold.ttf')
}

@font-face {
    font-family: BeelineSans;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    /* src: local("BeelineSans"),local("BeelineSans-Medium"),url('../fonts/BeelineSans-Medium.woff2') format("woff2") */
    src: url('../fonts/BeelineSans/BeelineSans-Medium.ttf')
}

@font-face {
    font-family: BeelineSans;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    /* src: local("BeelineSans"),local("BeelineSans-Regular"),url('../fonts/BeelineSans-Regular.woff2') format("woff2") */
    src: url('../fonts/BeelineSans/BeelineSans-Regular.ttf')
}

@font-face {
    font-family: 'Avenir Next Bold';
    src: url('../fonts/avenir/AvenirNext-Bold-01.ttf');
  }
  @font-face {
    font-family: 'Avenir Next BoldItalic';
    src: url('../fonts/avenir/AvenirNext-BoldItalic-02.ttf');
  }
  @font-face {
    font-family: 'Avenir Next MediumItalic';
    src: url('../fonts/avenir/AvenirNext-MediumItalic-07.ttf');
  }
  @font-face {
    font-family: 'Avenir Next Demibold';
    src: url('../fonts/avenir/AvenirNext-DemiBold-03.ttf');
  }
  @font-face {
    font-family: 'Avenir Next DemiBoldItalic';
    src: url('../fonts/avenir/AvenirNext-DemiBoldItalic-04.ttf');
  }
  @font-face {
    font-family: 'Avenir Next Italic';
    src: url('../fonts/avenir/AvenirNext-Italic-05.ttf');
  }
  @font-face {
    font-family: 'Avenir Next Medium';
    src: url('../fonts/avenir/AvenirNext-Medium-06.ttf');
  }
  @font-face {
    font-family: 'Avenir Next Regular';
    src: url('../fonts/avenir/AvenirNext-Regular-08.ttf');
  }

*{
    padding: 0;
    margin: 0;
}

html{
    scroll-behavior: smooth;
}

body{
    /* background-color: #333; */
    background-color: #fff;
    /* color: #fff; */
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    min-width: var(--main-content-width);
    -webkit-font-smoothing: antialiased;
}

/* .header{
    background-color: #222;
    padding: 25px 100px;
    font-weight: 600;
}

main{
    margin: 50px 100px;
    width: 50%;
    float: left;
}

aside{
    width: 20%;
    margin-top: 50px;
    margin-left: 50px;
    float: left;
}

main .user{
    margin-bottom: 50px;
    background-color: #3e8fa0;
    padding: 20px;
}

main .delete-icon{
    float: right;
    color: #134b57;
    cursor: pointer;
    transform: scale(2);
}

main .edit-icon{
    margin-right: 25px;
    float: right;
    color: #165664;
    cursor: pointer;
    transform: scale(2);
}

main .delete-icon:hover,
main .edit-icon:hover{
    opacity: .5;
}

form input:not([type="checkbox"]), form textarea, form button{
    width: 90%;
    padding: 15px 5%;
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    margin-bottom: 10px;
    border: 0;
    background: #222;
    color: #fff;
    outline: none;
    font-size: 15px;
}

form input[type="checkbox"]{
    margin: 10px;
}

form button{
    width: 100%;
    cursor: pointer;
    transition: transform 500ms ease;
}

form button:hover{
    transform: scale(1.1);
}

.user form {
    margin-top: 20px;
}

.user form input,
.user form textarea,
.user form button{
    background: #134b57;
}

.user form button:hover{
    transform: scale(1.01);
} */

.page-wrapper{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.s-bg{
    padding: 0 15px;
    width: 100%;
    max-width: var(--main-content-width);
    box-sizing: border-box;
}

.page-content{
    width: 100%;
    /* background: #fff; */
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
}

.contentWrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bb_spinner{
    width: 60px;
    height: 60px;
    border: 4px solid #fff;
    border-bottom: 5px solid #BEBEC9;
    border-left: 5px solid #BEBEC9;
    border-right: 5px solid #BEBEC9;
    border-radius: 100%;
    margin-left: calc(50% - 30px);
    animation: bn_spin 1s infinite linear;    
}

.bb_spinner.small{
    margin-top: 20px;
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    border-bottom: 2px solid #BEBEC9;
    border-left: 2px solid #BEBEC9;
    border-right: 2px solid #BEBEC9;
    border-radius: 100%;
}

.bb_spinner.medium{
    margin-top: 20px;
    width: 45px;
    height: 45px;
    border: 2px solid #fff;
    border-bottom: 2px solid #BEBEC9;
    border-left: 2px solid #BEBEC9;
    border-right: 2px solid #BEBEC9;
    border-radius: 100%;
}

@keyframes bn_spin {
    0% { transform: rotate(0deg); }
    20% { transform: rotate(90deg); }
    40% { transform: rotate(120deg); }
    100% { transform: rotate(360deg); }		
}

.m-t60{
    margin-top: 60px;
}

.m-b30{
    margin-bottom: 30px;
}

.coverPreview{
    object-fit: cover;
    width: 100%;
    height: 100%;
}