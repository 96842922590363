.mainBanner-backer{
    background: red;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    box-sizing: border-box;
    padding: 55px 0;
    background: #E39AA7;
    background-image: url("../../../img/tulipsBg.svg");
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
}

.mainBanner-wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.mainBanner-content-left, .mainBanner-content-right{
    flex: 1;
}

.mainBanner-content-covers{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 3px;
}
.mainBanner-content-cover{
    flex: 1;
}

.mainBanner-content-cover img{
    width: 100%;
    border-radius: var(--book-border-radius);
    aspect-ratio: 183/281;
}

.mainBanner-content-left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.mainBanner-content-title{
    color: #fff;
    font-family: BeelineSans;
    font-size: 40px;
    font-weight: 700;
    line-height: 36px;
    text-align: left;
}

.mainBanner-content-secondaryText{
    font-family: BeelineSans;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0%;
    color: #fff;
    margin-top: 12px;
    margin-bottom: 15px;
}

.mainBanner-content-greyBg{
    background: #D68594;
    border-radius: 32px;
    padding: 12px;
    color: #fff;
    font-family: BeelineSans;
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0%;
}

.mainBanner-content-button{
    margin-top: 24px;
    margin-bottom: 12px;
}

.mainBanner-content-smallText{
    color: #fff;
    font-family: 'BeelineSans';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
}