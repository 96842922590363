a:has(.buttonSecondary){
    all: unset;
    cursor: pointer;
}

.buttonSecondary{
    border: 1px solid #000;
    border-radius: 7px;
    padding: 5px 10px;
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    cursor: pointer;
}