.cookieNotificationPanel-wrapper{
    width: 100%;
    background: #433F3F;
    border-radius: 16px 16px 0 0;
    /* position: fixed; */
    /* bottom: 0; */
    /* left: 0; */
    padding: 16px 0;
    box-sizing: border-box;
    padding: 16px 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
}

.cookieNotificationPanel{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.cookieNotificationPanel-text{
    font-family: BeelineSans;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
}

.cookieNotificationPanel-text a{
    text-decoration: underline;
    color: #FEE02C;
}