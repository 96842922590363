.page-header{
    width: 100%;
    background: #fff;
    padding: 8px 0;
    border-bottom: 1px solid #EDEDED;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    /* margin-top: 62px; */
    position: sticky;
    z-index: 95;
    top: 62px;
    transition-duration: .3s;
}

.headerWrapper{
    display: flex;
    gap: 35px;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #000;
    box-sizing: border-box;
    font-family: 'BeelineSans';
}

.headerMenu{
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    border: 1px solid transparent;
    /* transition-duration: .2s; */
}

.headerMenu a{
    all: unset;
}

.headerLogo{
    height: 35px;
    cursor: pointer;
}

.headerLogo img{
    height: 100%;
}

.headerMenuItem{
    cursor: pointer;
    border-bottom: 2px solid transparent;
    /* padding-bottom: 5px; */
    /* margin-top: 7px; */
    padding: 7px 10px 5px;
}

.headerMenuItem:hover, .active .headerMenuItem, .headerSearchIcon.active{
    border-bottom: 2px solid #FFB612;
}

.headerSearchIcon{
    cursor: pointer;
    padding: 7px 10px 4px;
}

.headerMenu.searchPanelStyle{
    flex: 1;
    border: 1px solid red;
    /* top: -6px; */
    /* left: 0; */
    /* background: #FFFFFF; */
    border: 1px solid #FEE02C;
    border-radius: 8px;
    gap: 10px;
}

.page-header .headerSearchInput{
    flex: 1;
    font-size: 14px;
    border: none;
    padding: 0 10px 0 5px;
    outline: none;
    font-family: 'Roboto';
    font-weight: 300;
}

.page-header .headerSearchCloseGlyph{
    color: #9e9e9e;
    padding: 3px 5px 0 5px;
    cursor: pointer;
}

.headerUserData{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.headerUserData img{
    width: 16px;
    margin-right: 8px;
}

.headerUserLink{
    all: unset;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-right: 30px;
    cursor: pointer;
}

.headerLogout{
    cursor: pointer;
}