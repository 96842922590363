  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiperCentered .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .swiperCentered .swiper-slide .SliderCentered-cover>img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-sizing: border-box;
  }

  .swiperCentered .swiper-slide {
    /* width: auto; */
    width: 600px;
    }

  .swiperCentered .SliderCentered-wrapper{
    background: #E9FFCE;
    border-radius: 8px;
    padding: 25px 32px;
    /* padding-left: 153px; */
    /* padding-right: 32px; */
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    width: 570px;
    /* margin-bottom: 40px; */
    margin-left: 45px;
  }

  .swiperCentered .SliderCentered-content{
    flex: 5;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
    text-align: left;
  }

  .swiperCentered .SliderCentered-cover{
    flex: 2;
    margin-left: -70px;
    position: relative;
  }

  .swiperCentered .SliderCentered-cover>img{
    width: 100%;
    box-shadow: 14px 17px 21px rgb(35 47 65 / 20%);
    aspect-ratio: 1 / 1;
    background: linear-gradient(134.36deg, #FAFCFE 1.1%, #D5E4F0 100%);
    border-radius: 8px;
    border: 1px solid #efefef;
  }

  .swiperCentered .SliderCentered-content-title{
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #212529;
    text-align: left;
  }

  .swiperCentered .SliderCentered-content-author{
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    margin-top: 5px;
  }

  .swiperCentered .SliderCentered-content-categories{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 10px;
    height: 19px;
    overflow: hidden;
  }

  .swiperCentered .SliderCentered-content-categories-item{
    padding: 3px 10px;
    border-radius: 30px;
    background: #fff;
    font-size: 10px;
  }

  .swiperCentered .SliderCentered-content-description{
    margin-top: 15px;
    font-family: 'BeelineSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #000000;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    color: #212529;
  }

  .swiperCentered .SliderCentered-content-button{
    margin-top: 20px;
  }