.contentWrapper:has(.userContent){
    width: 100%;
}

.userContent{
    width: 100%;
}

.userContent a{
    all: unset;
}

.userContent .userNavigation{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 30px;
    margin-top: 50px;
    border-bottom: 1px solid #EEEEEE;
    width: 100%;
    padding-bottom: 0px;
}

.userContent .navigationItem{
    box-sizing: border-box;
    border-bottom: 2px solid transparent;
    transition-duration: .1s;
    font-size: 16px;
    color: #393939;
    font-family: "Arial";
    text-decoration: none;
    margin-bottom: 0px;
    padding: 0px 10px 10px;
    cursor: pointer;
}

.userContent .navigationItem.active{
    border-bottom: 2px solid #FFB612;
}

.userContent .userBooks .title{
    margin-top: 50px;
}

.userContent .userBooks .title h2{
    font-size: 28px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
}

.userContent .userBooks .title h2 span{
    color: #FFB612;
    margin-left: 10px;
}

.userContent .userBooks .booksArea{
    margin-top: 35px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-end;
    align-items: flex-start;
    justify-content: space-between;
    gap: calc((100% - 900px) / 5);
}

.userContent .userBooks .booksArea .bookContainerWrapper{
    min-width: 150px;
    flex: unset;
    margin-bottom: 30px;
}

.userContent .userBooks .booksArea::after {
    content: "";
    flex: auto;
}

.userContent .userBooks .booksArea .emptyBooks{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
}

.userContent .userBooks .booksArea .emptyImg{
    width: 250px;
}

.userContent .userBooks .booksArea .emptyImg img{
    width: 100%;
}

.userContent .userBooks .booksArea .emptyText{
    font-family: "Arial";
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.userSubscription{
    width: 100%;
    margin-top: 44px;
}

.userSubscription .subscriptions-bottomText{
    text-align: center;
    font-family: "Arial";
    margin-bottom: 60px;
    margin-top: 30px;
}

.userSubscription .subscriptions-bottomText a{
    text-decoration: underline;
    color: #FFB612;
    cursor: pointer;
}