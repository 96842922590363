.contentWrapper:has(.purchaseContent){
    width: 100%;
}

.purchaseContent>.topLine{
    margin: 24px 0;
    font-family: 'BeelineSans';
    font-size: 16px;
    font-weight: 400;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
}

.purchaseContent .contentLine{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 30px;
}

.purchaseContent .contentLine .leftPart{
    flex: 7;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
}

.purchaseContent .contentLine .leftPart h1{
    color: #313131;
    margin-bottom: 40px;
    font-family: 'BeelineSans';
    font-size: 40px;
    font-weight: 500;
    line-height: 38px;
    text-align: left;
}

.purchaseContent .contentLine .leftPart .bookInfo{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 20px;
    align-items: center;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookCover{
    flex: 4;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookDescription{
    flex: 8;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookCover.audio{
    flex: 5;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookDescription.audio{
    flex: 7;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookDescription{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookDescription a, .purchaseContent>.topLine a{
    all: unset;
    display: contents;
    cursor: pointer;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookDescription .info{
    flex: 1;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookDescription .info h2{
    font-family: 'BeelineSans';
    font-size: 21px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    color: #313131;    
    margin-bottom: 8px;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookDescription .info p{
    color: #9E9E9E;
    font-family: 'BeelineSans';
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;

}

.purchaseContent .contentLine .leftPart .bookInfo .bookDescription .navArrow{
    line-height: 34px;
    font-size: 21px;
}

.purchaseContent .contentLine .leftPart .bookInfo .bookCover img{
    width: 100%;
    border-radius: var(--book-border-radius);
}

.purchaseContent .contentLine .rightPart{
    flex: 5;
}

.purchaseContent .contentLine .rightPart .statusWrapper{
    padding: 32px 24px;
    width: 100%;
    border-radius: 32px;
    border: 1px solid #CDCDCD;
    box-sizing: border-box;
}

.purchaseContent .contentLine .rightPart .statusWrapper .bb_spinner.medium{
    margin-top: 0;
}

.purchaseContent .contentLine .rightPart .statusWrapper .defaultState h3{
    color: #313131;
    font-family: 'BeelineSans';
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.purchaseContent .contentLine .rightPart .statusWrapper .defaultState .paymentType{
    margin-top: 12px;
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    color: #313131;
    margin-bottom: 16px;
    gap: 10px;
    flex-direction: row;
    flex-wrap: nowrap;
}

.purchaseContent .contentLine .rightPart .statusWrapper .defaultState .rules{
    color: #313131;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
}

.purchaseContent .contentLine .rightPart .statusWrapper .defaultState .rules ul{
    margin-top: 16px;
    color: #9E9E9E;
    margin-left: 20px;
    margin-bottom: 24px;
}

.purchaseContent .contentLine .rightPart .statusWrapper .defaultState .rules li{
    margin-bottom: 8px;
}

.purchaseContent .contentLine .rightPart .statusWrapper .defaultState .price{
    color: #313131;
    font-family: 'BeelineSans';
    font-size: 40px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 16px;
}

.purchaseContent .contentLine .rightPart .statusWrapperBottom{
    margin-top: 16px;
    color: #BEBEC9;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.purchaseContent .contentLine .rightPart .statusWrapper .processingState .topLine{
    display: flex;
    font-size: 26px;
    color: #9E9E9E;
    justify-content: space-between;
    align-items: center;
}

.purchaseContent .contentLine .rightPart .statusWrapper .processingState .topLine h3{
    color: #313131;
    font-family: 'BeelineSans';
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.purchaseContent .contentLine .rightPart .statusWrapper .processingState p{
    color: #313131;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 12px;    
    margin-bottom: 32px;
}

.purchaseContent .contentLine .rightPart .statusWrapper .processingState p a{
    all: unset;
    text-decoration: underline;
    cursor: pointer;
}

.purchaseContent .contentLine .rightPart .statusWrapper:has(.successState){
    margin-left: 40%;
    width: 60%;
}

.purchaseContent .contentLine .rightPart .statusWrapper .successState .qrImg{
    width: 100%;
    box-sizing: border-box;
    background: #f5f5f5;
    border-radius: 25px;
    padding: 25px
}

.purchaseContent .contentLine .rightPart .statusWrapper .successState .qrImg img{
    width: 100%;
}

.purchaseContent .contentLine .rightPart .statusWrapper .successState p{
    color: #9E9E9E;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 12px;    
    margin-bottom: 24px;
}

.purchaseContent .contentLine .rightPart .statusWrapper .successState .badgesWrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 6px;
}

.purchaseContent .contentLine .rightPart .statusWrapper .successState .badgesWrapper a{
    width: calc(50% - 3px);
}

.purchaseContent .contentLine .rightPart .statusWrapper .successState .badgesWrapper img{
    width: 100%;
}

.purchaseContent .contentLine .rightPart .statusWrapper:has(.errorState){
    border: 1px solid #F14B4B;
}

.purchaseContent .contentLine .rightPart .statusWrapper .errorState .topLine{
    display: flex;
    font-size: 26px;
    color: #F14B4B;
    justify-content: space-between;
    align-items: center;
}

.purchaseContent .contentLine .rightPart .statusWrapper .errorState .topLine h3{
    color: #313131;
    font-family: 'BeelineSans';
    font-size: 24px;
    font-weight: 500;
    margin: 0;
}

.purchaseContent .contentLine .rightPart .statusWrapper .errorState p{
    color: #313131;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-top: 12px;    
    margin-bottom: 32px;
}