a{
    all: unset;
    cursor: pointer;
}

.page-content:has(.authorContent){
    flex: 1;
    justify-content: flex-start;
}

.authorContent{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
}

.authorContent h1{
    font-family: BeelineSans;
    font-size: 32px;
    font-weight: 300;
    line-height: 43.4px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 32px 0;
}

.authorContent h1 span{
    color: #FCB414;
}

.authorContent .contentWrapper:has(.authorContent){
    width: 100%;
}

.authorContent .authorBooks{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    align-items: flex-start;
    margin-bottom: 30px;
}

.authorContent .authorBooks .bookContainerWrapper{
    flex: unset;
    width: calc(100% / 6 - 25px);
}