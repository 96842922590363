.adultMask{
    position: absolute;
    width: calc(100% + 10px);
    height: calc(100% + 10px);
    left: -5px;
    top: -5px;
    display: flex;
    border-radius: var(--book-border-radius);
    justify-content: center;
    align-items: center;
    background: #fff;
    opacity: .6;
}

.adultMask img{
    max-width: 50%;
}

div:has(.adultMask)>img, div:has(.adultMask)>a>img{
    filter: blur(3px);
}

.sR-shelfBig .adultMask{
    border-radius: var(--bigbook-border-radius);
}

.sR-book .adultMask{
    border-radius: var(--singlebook-border-radius);
}