.policyContent{
    margin: 10px 0 20px;
}

.policyContent h1{
    padding: 20px 0;;
}

.policyContent h2{
    padding: 15px 0;;
}

.policyContent p{
    padding: 10px 0;;
}