.modalAuthorization{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;
}

.modalAuthorization h2{
    font-weight: 700;
    font-size: 32px;
    font-family: 'BeelineSans';
    font-style: normal;
    font-size: 32px;
    line-height: 52px;
    color: #000000;
    margin-bottom: 15px;
}

.modalAuthorization .regularText{
    color: #191C22;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
}

.modalAuthorization .smallText{
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #727281;
    margin-top: 16px;
    line-height: 15px;
}

.modalAuthorization .smallText a{
    all: unset;
    cursor: pointer;
    color: #1790ff;
}

.modalAuthorization .phoneNumber{
    margin-top: 8px;
    margin-bottom: 32px;
    color: #191C22;
    font-family: BeelineSans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    height: 44px;
    border-radius: 8px;
    width: 100%;
    background: #fff;
    border: 1px solid #DFE0E5;
    padding: 0 12px;
    text-align: left;
    outline: none;
    box-sizing: border-box;
}

.modalAuthorization .errorText{
    color: red;
}

.modalAuthorization .errorBorder{
    border: 1px solid red !important;
    color: red !important;
}

.modalAuthorization .waitText{
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin: 0;
    color: #727281;
}

.modalAuthorization .bb_spinner{
    margin-top: 64px;
}