.modal-backdrop{
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 40;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.9);
    /* backdrop-filter: blur(20px); */
    align-items: center;
    z-index: 999;
}

.modal-content{
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    padding: 50px 30px;
    width: 544px;
    /* margin: auto; */
    height: fit-content;
    max-height: 100%;
    box-sizing: border-box;
    position: relative;
}

.modal-content .modal-header{
    display: flex;
    align-items: center;
}

.modal-content .modal-body{
    color: #666;
}

.modal-content .modal-footer{
    display: flex;
    justify-content: flex-end;
    gap: 24px;
    align-items: center;
    margin-top: 40px;
}

.modal-closeButton{
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
}