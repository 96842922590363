.page-footer{
    background: #141414;
    width: 100%;
    padding: 30px 0 25px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Roboto';
    margin-top: auto;
    color: #9e9e9e;
    font-size: 16px;
}

.footerWrapper{
    display: flex;
    gap: 35px;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: nowrap;
}

.footerWrapper a{
    all: unset;
    cursor: pointer;
}

.footerWrapper p{
    margin-top: 0;
    margin-bottom: 10px;
}

.footer-column-2{
    text-align: center;
}

.footer-copyright{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.footer-copyright img{
    margin-right: 10px;
    height: 24px;
}

.footer-column-3 h3{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: #fff;
    font-size: 23px;
    margin-bottom: 20px;
    font-family: 'BeelineSans';
    font-weight: 300;
}

.footer-column-3 h3 img{
    margin: 0 5px;
}

.footer-badges{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.footer_apple{
	background: url("../../img/footer_appstore.svg");
}

.footer_apple:hover{
	background: url("../../img/footer_appstore_active.svg");
}
.footer_android{
	background: url("../../img/footer_android.svg");
}

.footer_android:hover{
	background: url("../../img/footer_android_active.svg");
}

.footer_huawei{
	background: url("../../img/icon-huawei-inact.png");
}

.footer_huawei:hover{
	background: url("../../img/icon_huawei_act.png");
}

.footer_rustore{
	background: url("../../img/rustore_grey.svg");
}

.footer_rustore:hover{
	background: url("../../img/rustore_blue.svg");
}

.footer_apple, .footer_android, .footer_huawei, .footer_rustore{
	height: 30px;
    width: 24px;
    margin-right: 10px;
    float: left;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    scale: .7;
}

.footer_huawei{
	width: 38px;
}

.footer_rustore{
    width: 30px;
}

.footer_apple:hover, .footer_android:hover, .footer_huawei:hover, .footer_rustore:hover{
	background-size: cover;
	background-position: center;
}