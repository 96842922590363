.header-banner a{
    all: unset !important;
}

.header-banner{
    width: 100%;
    background: linear-gradient(90deg, #FDFBFB 0%, #EBEDEE 100%);
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    position: sticky;
    z-index: 99;
    top: 0;
}

.headerBannerWrapper{
    display: flex;
    gap: 35px;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: nowrap;
    box-sizing: border-box;
    justify-content: space-between;
}

.headerBannerWrapper-text{
    line-height: 62px;
    font-family: 'BeelineSans';
    font-size: 17px;
    font-weight: 700;
    color: #000;
    white-space: nowrap;
}

.headerBannerWrapper-button{
    color: #000;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}

.headerBannerWrapper-button img{
    cursor: pointer;
}

.headerBannerWrapper-button>a{
    height: 38px !important 
}

.headerBannerWrapper-button>a>img{
    height: 100%;
}