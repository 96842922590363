.page-content:has(.bookContent){
    flex: 1;
    background: #f5f5f5;
    justify-content: flex-start;
}

.bookContent{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 32px;
}

.contentWrapper .s-bg:has(.bookContent), .bookContent>.s-bg{
    max-width: 1228px;
    min-width: 1228px;
}

.bookContent>.bottomLine>.s-bg{
    padding: 0;
}

    .bookContent .topLine{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 30px;
        justify-content: space-between;
        background: #fff;
        padding: 44px;
        border-radius: 44px;
        align-items: center;
        margin-top: 15px;
    }

        .bookContent .topLine .descriptionColumn{
            flex: 1;
            box-sizing: border-box;
            margin-bottom: 80px;
        }

            .bookContent .topLine .descriptionColumn .bookType{
                font-family: BeelineSans;
                font-size: 18px;
                font-weight: 500;
                line-height: 22px;
                text-align: left;
                color: #9E9E9E;
            }

            .bookContent .topLine .descriptionColumn h1{
                margin-top: 8px;
                margin-bottom: 18px;
                font-family: BeelineSans;
                font-size: 36px;
                font-weight: 400;
                line-height: 40px;
                text-align: left;                
            }

            .bookContent .topLine .descriptionColumn .contentLine{
                color: #9E9E9E;
                font-family: 'Avenir Next Regular';
                font-size: 16px;
                font-weight: 400;
                line-height: 27px;
                text-align: left;                
            }

            .bookContent .topLine .descriptionColumn .contentLine.adult{
                margin-top: 8px;
            }

            .bookContent .topLine .descriptionColumn .contentLine span{
                color: #313131;
            }

            .bookContent .topLine .descriptionColumn .contentLine a{
                all: unset;
                color: #313131;
                cursor: pointer;
            }

            .bookContent .topLine .descriptionColumn .categories{
                margin-top: 18px;
            }

            .bookContent .topLine .descriptionColumn .categories .category a{
                all: unset;
            }

            .bookContent .topLine .descriptionColumn .categories .category{
                background: #fff;
                width: auto;
                color: #313131;
                font-size: 14px;
                border-radius: 16px;
                border: 1px solid #CDCDCD;
                padding: 8px 13px;
                margin: 0 10px 10px 0;
                float: left;
                font-family: 'Avenir Next Regular';
                line-height: 14px;
                transition-duration: .2s;
            }

        .bookContent .topLine .coverColumn{
            flex: 1;
            box-sizing: border-box;
        }

            .bookContent .topLine .coverColumn .cover{
                position: relative;
            }

            .bookContent .topLine .coverColumn .cover:not(:has(img)){
                width: 100%;
                aspect-ratio: 100/143;
            }

            .bookContent .topLine .coverColumn .cover img{
                width: 100%;
                border-radius: var(--book-border-radius);
                box-sizing: border-box;
            }

            .bookContent .topLine .coverColumn .buttons{
                margin-top: 18px;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                gap: 16px;
            }

            .bookContent .topLine .coverColumn .buttons .buttonsLine{
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                gap: 16px;
                align-items: center;
                justify-content: space-between;
            }

            .bookContent .topLine .coverColumn .buttons a, .bookContent .topLine .coverColumn .buttons .button{
                flex: 1;
            }

        .bookContent .topLine .subsColumn{
            flex: 1;
            box-sizing: border-box;
        }
    
    .bookContent .middleLine{
        background: #fff;
        padding: 44px;
        border-radius: 44px;
    }

    .bookContent .middleLine h2{
        font-family: BeelineSans;
        font-size: 32px;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        margin-bottom: 8px;
    }

    .bookContent .middleLine .description{
        font-family: 'Avenir Next Regular';
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        
    }
    
    .bookContent .bottomLine{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        background: #fff;
        padding: 44px;
        border-radius: 44px;
        align-items: center;
        margin-bottom: 32px;
    }

    .subsColumn .wrapper{
        width: 100%;
        border: .5px solid #CDCDCD;
        border-radius: 32px;
        padding: 32px 24px;
        color: #313131;
        position: relative;
        box-sizing: border-box;
        margin-bottom: 80px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;
        gap: 12px
    }

    .subsColumn .wrapper .mainText{
        font-family: 'BeelineSans';
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        line-height: 24px;
        max-width: calc(100% - 28px);
    }

    .subsColumn .wrapper .smallText{
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 18px;
    }

    .subsColumn .wrapper .result{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: nowrap;        
        gap: 12px;
    }

    .subsColumn .wrapper .result .firstLine{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        width: 100%;
    }

    .subsColumn .wrapper .result .firstLine .leftText{
        font-family: 'BeelineSans';
        font-size: 20px;
        font-weight: 500;
        text-align: left;
        line-height: 24px;
        max-width: calc(100% - 28px)
    }

    .subsColumn .wrapper .result .firstLine .rightCheck img{
        height: 24px;
    }

    .subsColumn .wrapper .result .secondLine{
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        line-height: 18px;        
    }

    .subsColumn .wrapper .discountBadge{
        width: 158px;
        height: 125px;
        background: url("../../img/sale.svg");
        position: absolute;
        left: -158px;
        top: calc(50% - 63px);
        text-align: right;
        color: #801A90;
        font-size: 16px;
        font-family: BeelineSans;
        font-weight: 600;
        line-height: 20px;
        padding: 0 10px;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
    }

    .subsColumn .wrapper .discountBadge .content{
        margin-right: 20px;
    }

    .subsColumn .wrapper .discountBadge .content span{
        font-size: 36px;
    }

    .subsColumn .wrapper .upBadge{
        width: 140px;
        height: 125px;
        background: url("../../img/upBadge.svg");
        position: absolute;
        left: -138px;
        top: calc(50% - 63px);
        text-align: right;
        color: #fff;
        font-size: 16px;
        font-family: BeelineSans;
        font-weight: 600;
        line-height: 20px;
        padding: 0 10px;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-direction: column;
    }

    .subsColumn .wrapper .upBadge .content{
        margin-right: 20px;
    }

    .subsColumn .wrapper .upBadge .content span{
        font-size: 36px;
    }    

    .bookContent:has(.adultMask) .bookContainer-cover-vinil{
        display: none;
    }
    
    .bookContent .bookContainer-cover-vinil{
        position: absolute;
        width: 13%;
        top: 40%;
        right: -3%;
        aspect-ratio: 37 / 74;
    }
    
    .bookContent .bookContainer-cover-vinil img{
        width: 100%;
        height: 100%;
    }  