.modalAdult{
    box-sizing: border-box;
    padding: 0 30px;
}

.modalAdult img{
    width: 144px;
    margin-bottom: 10px;
}

.modalAdult h2{
    font-weight: 300;
    font-size: 32px;
    font-family: 'BeelineSans';
    font-style: normal;
    font-size: 32px;
    line-height: 52px;
    color: #000000;
    margin-bottom: 5px;
}

.modalAdult p{
    font-family: 'BeelineSans';
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 20px;
}

.modalAdult .buttonsArea{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
}