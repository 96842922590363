.badgesArea{
    min-height: 20px;
    height: auto;
    position: absolute;
    left: -5px;
    bottom: 13px;
    z-index: 20;
    max-width: 90%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;    
}

.badgesArea .badges:not(:last-child){
    margin-bottom: 5px;
}

.badges-sale{
    background: #FFA4E2;
    font-family: 'BeelineSans';
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.badges-exclusive{
    background: #892DFF;
    font-family: 'BeelineSans';
    color: #fff;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap; 
    justify-content: space-between;   
}

.badges-free{
    background: #FEE02C;
    font-family: 'BeelineSans';
    color: #313131;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap; 
    justify-content: space-between;   
}

.badges{
    font-weight: 500;
    font-size: 10px;
    padding: 4px 9px;
    border-radius: 4px;
}

.badges svg{
    margin-left: 5px;
}

.bigBadges .badges{
    font-size: 13pt;
    padding: 6px 12px;
    border-radius: 8px;
}

.badgesArea.bigBadges{
    bottom: 20px;
    left: -15px;
}