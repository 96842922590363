.c-topButton{
    position: fixed;
    right: 50px;
    bottom: 50px;
    cursor: pointer;
    display: block;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    overflow: hidden;
    z-index: 9;
}

.c-topButton img{
    height: 100%;
    width: 100%;
}